import React, { Component } from "react";
import PropTypes from "prop-types";
import { toUpper } from "lodash";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import {
    Grid,
    Typography,
    Card,
    CardMedia,
    CardContent,
    Paper
} from '@mui/material';
import TruncateMarkup from "react-truncate-markup";
import { withStyles } from "@mui/styles";
import EventsCard from './EventsCard';
import DialogBox from './DialogBox';

import {
    formatDateLong,
    isCurrentEvent,
} from '../utils/dates';
import {
    constants,
    PADDING_MEDIUM,
    PADDING_SMALL,
    paddingContentContainer,
} from "../constants";

const zoom = window.zoomEvent ? window.zoomEvent : 1;
const titleColorEvent = window.titleColorEvent ? window.titleColorEvent : '#000000';
const dateColorEvent = window.dateColorEvent ? window.dateColorEvent : '#E40046';

const IMAGE_MAX_HEIGHT = 150;

const imageMaxHeightEvent = parseInt(window.imageMaxHeightEvent, 10)
    ? parseInt(window.imageMaxHeightEvent, 10)
    : IMAGE_MAX_HEIGHT;

const hideImage = imageMaxHeightEvent === -1;

class EventsCardCarousel extends Component {
    state = {
        open: false,
    };

    getCityOrAggloName = (event) => {
        if (event) {
            return event.agglo ? event.agglo_name : event.city_name;
        }
        return "";
    };

    generateEventsCard = event => {
        return (
            <div>
                <EventsCard event={event} key={event.id} popupCarousel />
            </div>
        );
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    onHandleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes, event } = this.props;
        const { open } = this.state
        if (!event) return null;

        const cityName = this.getCityOrAggloName(event);
        const isOnlyDate =
            event.start_date && !event.displayedDistanceToPositionOrCity;

        return (
            <div>
                <Card className={classes.card} onClick={this.handleClickOpen}>
                    <div className={classes.cardLink}>
                        {!hideImage ? (
                            <LazyLoadComponent
                                placeholder={<div className={classes.media} />}
                            >
                                <CardMedia
                                    component="img"
                                    alt={event.image ? event.title : null}
                                    className={classes.media}
                                    image={event.image}
                                    title={event.title}
                                />
                            </LazyLoadComponent>
                        ) : null}
                        <LazyLoadComponent
                            placeholder={<div style={{ height: 65 }} />}
                        >
                            <Grid
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-end"
                                container
                            >
                                <Grid style={{ width: isOnlyDate ? "100%" : "63%" }} item>
                                    {event.start_date ? (
                                        <Paper
                                            elevation={0}
                                            square
                                            className={classes.startDatePaper}
                                        >
                                            <Typography
                                                align="center"
                                                className={classes.dateText}
                                            >
                                                {isCurrentEvent(event.start_date, event.end_date)
                                                    ? toUpper("En ce moment")
                                                    : formatDateLong(event.start_date)}
                                            </Typography>
                                        </Paper>
                                    ) : null}
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className={classes.cityText}
                                        variant={"subtitle2"}
                                        component="div"
                                    >
                                        <TruncateMarkup
                                            lines={1}
                                        >
                                            <div>{toUpper(cityName)}</div>
                                        </TruncateMarkup>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </LazyLoadComponent>
                    </div>
                    <CardContent title={event.title} style={{ paddingBottom: 16 }}>
                        <Grid
                            style={{ height: "100%" }}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            container
                        >
                            <Typography
                                className={classes.titleText}
                                variant="h6"
                                component="div"
                            >

                                <TruncateMarkup
                                    lines={2}
                                >
                                    <div>{event.title}</div>
                                </TruncateMarkup>
                            </Typography>
                        </Grid>
                    </CardContent>
                </Card>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <DialogBox
                        open={open}
                        content={this.generateEventsCard(event)}
                        handleClose={this.onHandleClose}
                        fullWidth
                    />
                </div>
            </div>
        );
    }
}

EventsCardCarousel.propTypes = {
    classes: PropTypes.objectOf(PropTypes.object()).isRequired,
    event: PropTypes.objectOf(PropTypes.object()).isRequired,
};

const styles = () => ({
    card: {
        cursor: 'pointer',
        boxSizing: "border-box",
        paddingRight: PADDING_MEDIUM,
        paddingLeft: PADDING_MEDIUM,
        paddingTop: PADDING_SMALL,
        paddingBottom: PADDING_SMALL,
        borderRadius: 0,
        boxShadow: "none",
        minHeight: constants.EVENT_CARD,
        width: "100%",
        backgroundColor: window.backgroundColorEvent
    },
    cardLink: {
        background: "white",
        boxShadow: "0px 1px 3px grey",
        borderRadius: 5,
        paddingBottom: 10,
        cursor: 'pointer',
    },
    media: {
        objectFit: "cover",
        height: imageMaxHeightEvent,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        boxShadow: "0px 1px 0px grey"
    },
    content: {
        ...paddingContentContainer,
    },
    startDatePaper: {
        paddingLeft: 1,
        paddingRight: 1,
        paddingTop: 5,
        paddingBottom: 5,
        borderTopLeftRadius: hideImage ? 5 : '',
        borderTopRightRadius: hideImage ? 5 : '',
        backgroundColor: "rgba(255, 255, 255, 0.9)",
    },
    distancePaper: {
        paddingLeft: 1,
        paddingRight: 1,
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: "rgba(17, 43, 92, 1)",
    },
    dateText: {
        color: dateColorEvent,
        fontWeight: "bolder"
    },
    cityText: {
        width: "100%",
        color: dateColorEvent,
    },
    titleText: {
        color: titleColorEvent,
        width: "100%",
        fontWeight: "600",
        fontSize: 18 * zoom,
        paddingTop: 4,
        textAlign: "center"
    }
});

export default withStyles(styles)(EventsCardCarousel);
